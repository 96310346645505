<template>
<div class="justify-center">
    <loading :is-show="loadingDialog" :is-done="loadingFinished" :is-html="isHtml" :message="loadingMessage" v-on:close-dialog="closeLoadingDialog" />
    <v-container class="container--fluid grid-list-md text-center">
        <h1>Visa Settlement & Reports</h1>
    </v-container>
    <v-container fluid pa-0>
        <v-row align="center" justify="center" 
            style="height:100vh" dense>
            <v-col cols="12" lg="6" md="6" class="lighten-2 fill-height d-flex flex-column align-center">
                <v-card elevation="10" outlined shaped tile padding="20px" class="justify-center" height="250px" width="500px">
                    <v-row class="ma-1">
                        <v-col>
                            <v-menu v-model="toDateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px" data-cy="date-menu-to">
                                <template v-slot:activator="{ on }">
                                    <v-text-field label="Business Date" prepend-icon="mdi-calendar" readonly :value="toDisplayDateCreated" v-on="on" data-cy="date-menu-to"></v-text-field>
                                </template>
                                <v-date-picker locale="en-in" v-model="dateCreatedVal" no-title @input="toDateMenu = false" :max="maxDate" data-cy="date-menu-to">
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-row class="ma-1">
                        <v-col>
                            <v-btn dark medium color=success @click="downloadCTF()" data-cy="button-search">
                                <v-icon dark medium left>mdi-cloud-download</v-icon>Download
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</div>
</template>

<script>
import {
    mapGetters
} from 'vuex';
import API from '@/api/API'
import moment from 'moment';
import Loading from '@/views/components/Loading.vue'

export default {
    name: 'Reports',
    components: {
        Loading,
    },
    data: () => ({
        reportType: '',
        headers: [],
        records: [],
        isNotMerchant: false,
        merchantList: [],
        merchants: [],
        selectedMerchant: {},
        loading: true,
        fromDateMenu: false,
        fromDateVal: "",
        toDateMenu: false,
        dateCreatedVal: "",
        dateCreatedParam: "",
        dateCreatedPicked: "",
        minDate: "1997-01-01",
        maxDate: "",
        fromMax: "",
        toMin: "",
        page: 1,
        totalReports: 0,
        numberOfPages: 0,
        options: {
            rowsPerPage: 10
        },
        offset: 0,
        limit: 0,
        displayReports: [],
        remainderReports: [],
        loadingMessage: "",
        loadingDialog: false,
        loadingFinished: false,
        isHtml: false,
    }),
    computed: {
        ...mapGetters([
            'attributes'
        ]),
        toDisplayDateCreated() {
            this.fromMax = moment().format(this.dateCreatedVal)
            return this.dateCreatedVal;
        },
    },
    watch: {
        options: {
            handler() {
                console.log('options');
                this.queryData(false);
                this.setData();
            },
        },
        merchants: {
            handler(value) {
                this.merchantList = value
            }
        },
        deep: true,
    },
    async mounted() {
        console.log('mounted')
        this.reportType = this.$route.name
        this.setDefaultDateRange()
    },
    methods: {
        async downloadCTF() {
            this.loadingDialog = true
            this.isHtml = true
            this.loadingFinished = false
            this.loadingMessage = `Downloading...`
            //set date created value to date created parameter then set date created back to original format and pass date created parameter to API
            this.dateCreatedPicked = this.dateCreatedVal
            this.dateCreatedVal = this.dateCreatedParam
            this.dateCreatedVal = this.dateCreatedPicked
            const downloadResponse = await API.downloadVisaCTF(this.dateCreatedPicked,this.getDateFormat(this.dateCreatedVal))
            this.loadingFinished = true
            if (!downloadResponse || downloadResponse.error) {
                this.isHtml = false
                this.loadingMessage = downloadResponse ? downloadResponse.error : "Error downloading file."
            } else {
                this.loadingMessage = `Download complete.`
            }
        },
        closeLoadingDialog(type) {
            this.loadingDialog = false
        },
        getToday() {
            const today = moment().format("yyyy-MM-DD")
            return today        
        },
        getDateFormat(value) {
            if (value) {
                return moment(String(value)).format('yyyyMMDD HHmmssSSS')
            } 
        },
        setDefaultDateRange() {
            this.dateCreatedVal = this.getToday()
            this.maxDate = moment().format("yyyy-MM-DD")
            this.fromMax = moment().format("yyyy-MM-DD")
            this.toMin = moment().format(this.dateCreatedVal)
        },
        setDefaultData() {
            this.records = []
        },
        clearToDate() {
            this.dateCreatedVal = ''
            this.toDateMenu = false
        }
    }
};
</script>
